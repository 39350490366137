var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.logoContainer},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require('@/assets/img/logo-sm.png'),"alt":""}})])],1),_c('header',{class:_vm.$style.header},[_c('div',{class:_vm.$style.searchInputContainer},[_c('BaseInput',{class:_vm.$style.searchInput,attrs:{"type":"text","placeholder":("Search " + (Number.isInteger(_vm.matchingTracksCount)
            ? (_vm.matchingTracksCount + " results . . .")
            : ''))},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('div',{class:_vm.$style.headerActionsContainer},[_c('BaseButton',{attrs:{"tag":"router-link","to":_vm.dashboardLink}},[_vm._v("Dashboard")]),_c('SaveDataActions',{attrs:{"tracks":_vm.tracks,"selectedTracksNum":_vm.selectedTracksNum,"selectedTracks":_vm.selectedTracks,"fetchItemDetails":_vm.fetchItemDetails}})],1)]),_c('main',[_c('div',{ref:"tableContainer",class:_vm.$style.tableContainer},[(_vm.searchTracksStatusPending && !_vm.tracks.length)?_c('BaseSpinner',{attrs:{"show":""}}):_c('table',{class:_vm.$style.table},[_c('thead',{class:_vm.$style.tableHeader},[_c('tr',[_c('th',[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.tracks.length && _vm.selectedTracksNum === _vm.tracks.length},on:{"change":_vm.onChangeAllSelectedTracks}})]),_c('th',[_vm._v("Track")]),_c('th',[_vm._v("Performers")]),_c('th',[_vm._v("ISRC")])])]),_c('tbody',[_vm._l((_vm.tracks),function(ref){
            var id = ref.id;
            var title = ref.title;
            var performers = ref.performers;
            var writers = ref.writers;
            var publishers = ref.publishers;
            var isrc = ref.isrc;
            var hfa_code = ref.hfa_code;
            var iswc = ref.iswc;
return _c('tr',{key:id},[_c('td',[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedTracks[id]},on:{"change":function($event){return _vm.onChangeSelectedTrack($event, id, isrc)}}})]),_c('td',[_c('button',{class:_vm.$style.titleButton,on:{"click":function($event){$event.preventDefault();return _vm.openTrackDetailsModal({
                    id: id,
                    title: title,
                    performers: performers,
                    writers: writers,
                    publishers: publishers,
                    isrc: isrc,
                    hfa_code: hfa_code,
                    iswc: iswc,
                  })}}},[_vm._v(" "+_vm._s(title)+" ")])]),_c('td',[_vm._v(_vm._s(performers))]),_c('td',[_vm._v(_vm._s(isrc))])])}),(_vm.tracks.length)?_c('LoadMore',{attrs:{"tag":"tr"},on:{"loadMore":_vm.onLoadMoreItems}}):_vm._e()],2)])],1)]),_c('TrackDetailsModal',{attrs:{"fetchTrackDetailsStatus":_vm.fetchTrackDetailsStatus,"track":_vm.tracksDetails[_vm.showTrackDetailsIsrc],"tracks":_vm.tracks},on:{"next":_vm.openTrackDetailsModal,"prev":_vm.openTrackDetailsModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }