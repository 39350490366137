<template>
  <div :class="$style.container">
    <div :class="$style.logoContainer">
      <router-link to="/">
        <img :src="require('@/assets/img/logo-sm.png')" alt="" />
      </router-link>
    </div>
    <header :class="$style.header">
      <div :class="$style.searchInputContainer">
        <BaseInput
          :class="$style.searchInput"
          type="text"
          :placeholder="`Search ${
            Number.isInteger(matchingTracksCount)
              ? `${matchingTracksCount} results . . .`
              : ''
          }`"
          v-model="query"
        />
      </div>
      <div :class="$style.headerActionsContainer">
        <BaseButton tag="router-link" :to="dashboardLink">Dashboard</BaseButton>
        <SaveDataActions
          :tracks="tracks"
          :selectedTracksNum="selectedTracksNum"
          :selectedTracks="selectedTracks"
          :fetchItemDetails="fetchItemDetails"
        />
      </div>
    </header>
    <main>
      <div ref="tableContainer" :class="$style.tableContainer">
        <BaseSpinner v-if="searchTracksStatusPending && !tracks.length" show />
        <table v-else :class="$style.table">
          <thead :class="$style.tableHeader">
            <tr>
              <th>
                <input
                  type="checkbox"
                  :checked="
                    tracks.length && selectedTracksNum === tracks.length
                  "
                  @change="onChangeAllSelectedTracks"
                />
              </th>
              <th>Track</th>
              <th>Performers</th>
              <th>ISRC</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="{
                id,
                title,
                performers,
                writers,
                publishers,
                isrc,
                hfa_code,
                iswc,
              } of tracks"
              :key="id"
            >
              <td>
                <input
                  :checked="selectedTracks[id]"
                  type="checkbox"
                  @change="onChangeSelectedTrack($event, id, isrc)"
                />
              </td>
              <td>
                <button
                  :class="$style.titleButton"
                  @click.prevent="
                    openTrackDetailsModal({
                      id,
                      title,
                      performers,
                      writers,
                      publishers,
                      isrc,
                      hfa_code,
                      iswc,
                    })
                  "
                >
                  {{ title }}
                </button>
              </td>
              <td>{{ performers }}</td>
              <td>{{ isrc }}</td>
            </tr>
            <LoadMore
              v-if="tracks.length"
              tag="tr"
              @loadMore="onLoadMoreItems"
            />
          </tbody>
        </table>
      </div>
    </main>
    <TrackDetailsModal
      :fetchTrackDetailsStatus="fetchTrackDetailsStatus"
      :track="tracksDetails[showTrackDetailsIsrc]"
      :tracks="tracks"
      @next="openTrackDetailsModal"
      @prev="openTrackDetailsModal"
    />
  </div>
</template>

<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import {
  searchIsrcUploadResults,
  fetchTracksDetails,
  fetchIsrcUploadedFileData,
} from "@/api/isrcApi";
import { API_STATUS } from "@/constants/apiStatus";
import { withAsync } from "@/helpers/withAsync";
import LoadMore from "@/components/common/LoadMore";
import { debounce, pick } from "lodash-es";
import SaveDataActions from "./components/SaveDataActions";
import TrackDetailsModal from "./components/TrackDetailsModal";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "Search",
  components: {
    LoadMore,
    SaveDataActions,
    TrackDetailsModal,
  },
  props: {
    // fileUploadedId: {
    //   type: String,
    // },
    fetchItemDetails: {
      type: Function,
      required: true,
    },
    searchItems: {
      type: Function,
      required: true,
    },
    fetchItemsCount: {
      type: Function,
      required: true,
    },
    dashboardLink: {
      type: [String, Object],
      default: "/",
    },
  },
  data() {
    return {
      query: "",
      tracks: [],
      page: 1,
      loadMoreStatus: IDLE,
      searchTracksStatus: IDLE,
      fetchTrackDetailsStatus: IDLE,
      limitReached: false,
      selectedTracks: {},
      showTrackDetailsIsrc: null,
      tracksDetails: {},
      matchingTracksCount: null,
    };
  },
  created() {
    this.queryResultsCache = new Map();
    this.searchTracksDebounced = debounce(this.initSearchTracks, 500);
    this.initSearchTracks(this.query);
    this.initFetchItemsCount();
  },
  watch: {
    query(q) {
      this.limitReached = false;
      this.selectedTracks = {};
      this.page = 1;
      this.$refs.tableContainer.scrollTo(0, 0);
      if (this.queryResultsCache.has(q || "ALL")) {
        const { page, tracks, limitReached } = this.queryResultsCache.get(
          q || "ALL"
        );
        this.page = page;
        this.tracks = tracks;
        this.limitReached = limitReached;
        return;
      }
      this.searchTracksDebounced(q);
    },
  },
  computed: {
    ...apiStatusComputedFactory("loadMoreStatus"),
    ...apiStatusComputedFactory("searchTracksStatus"),
    ...apiStatusComputedFactory("fetchTrackDetailsStatus"),
    trackIds() {
      return this.tracks.map((track) => track.id);
    },
    selectedTracksNum() {
      return Object.keys(this.selectedTracks).length;
    },
  },
  methods: {
    async initFetchItemsCount() {
      const { response, error } = await withAsync(() => this.fetchItemsCount());
      if (error) {
        console.error(error);
        return;
      }
      this.matchingTracksCount = response.matchedCodesCount;
    },
    computeId(track, idx) {
      return `${track.title}-${track.isrc}-${idx}`;
    },
    onChangeSelectedTrack(e, id, isrc) {
      if (e.target.checked) {
        this.$set(this.selectedTracks, id, isrc);
      } else {
        this.$delete(this.selectedTracks, id);
      }
    },
    selectAllTracks() {
      this.selectedTracks = this.trackIds.reduce((acc, trackId) => {
        acc[trackId] = true;
        return acc;
      }, {});
    },
    deselectAllTracks() {
      this.selectedTracks = {};
    },
    onChangeAllSelectedTracks(e) {
      if (e.target.checked) {
        this.selectAllTracks();
      } else {
        this.deselectAllTracks();
      }
    },
    async onLoadMoreItems() {
      try {
        if (
          this.searchTracksStatus === PENDING ||
          this.loadMoreStatus === PENDING ||
          this.limitReached
        )
          return;
        this.loadMoreStatus = PENDING;
        this.page++;
        await this.initSearchTracks(this.query, true);
        this.loadMoreStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.loadMoreStatus = ERROR;
      }
    },
    async initSearchTracks(query, loadingMore = false) {
      if (!loadingMore) {
        this.limitReached = false;
      }
      this.searchTracksStatus = PENDING;
      const { response, error } = await withAsync(() =>
        this.searchItems({
          query,
          page: this.page,
        })
      );
      if (error) {
        console.error(error);
        this.searchTracksStatus = ERROR;
        return;
      }
      if (query !== this.query) return;
      if (loadingMore) {
        if (!response.length) {
          this.limitReached = true;
        } else {
          this.tracks.push(...this.withId(response));
        }
      } else {
        this.tracks = this.withId(response);
      }
      this.queryResultsCache.set(query || "ALL", {
        page: this.page,
        tracks: this.tracks,
        limitReached: this.limitReached,
      });
      this.searchTracksStatus = SUCCESS;
    },
    withId(tracks) {
      return tracks.map((track, idx) => {
        return track.id
          ? track
          : {
              ...track,
              id: this.computeId(track, idx),
            };
      });
    },
    prepareTrackDetailsResponse(response, track) {
      const publisherRoles = ["E", "SE", "AM"];
      const writers = [];
      const publishers = [];
      let hfa_code = "";
      let iswc = "";
      let confidence;
      let performers = "N/A";
      for (const item of response) {
        const {
          alias,
          interested_party,
          artist_name,
          role,
          society,
          ipi,
          party_admin,
          name,
        } = item;
        hfa_code = item.hfa_code || hfa_code;
        iswc = item.iswc || iswc;
        confidence = item.confidence || confidence;
        performers = item.performers || performers;
        if (
          publisherRoles.some((publisherRole) =>
            role.toLowerCase().includes(publisherRole.toLowerCase())
          )
        ) {
          publishers.push({
            interested_party,
            party_admin,
            role,
            society,
            ipi,
            name,
            alias,
          });
        } else {
          writers.push({
            interested_party,
            artist_name,
            role,
            society,
            ipi,
            name,
            alias,
          });
        }
      }

      return {
        ...track,
        writers,
        publishers,
        hfa_code,
        iswc,
        confidence,
        performers,
      };
    },
    async openTrackDetailsModal(track) {
      const { isrc } = track;
      this.showTrackDetailsIsrc = isrc;
      this.$vfm.show("track-details-modal");
      if (this.tracksDetails[isrc]) return;
      this.tracksDetails[isrc] = track;
      this.fetchTrackDetailsStatus = PENDING;
      const { response, error } = await withAsync(() =>
        this.fetchItemDetails([isrc])
      );

      if (error) {
        console.error(error);
        this.fetchTrackDetailsStatus = ERROR;
        return;
      }

      this.tracksDetails[isrc] = this.prepareTrackDetailsResponse(
        response,
        track
      );
      this.fetchTrackDetailsStatus = SUCCESS;
    },
  },
};
</script>
<style module lang="scss">
$primary-text: #373a3c;
.container {
  max-width: 1400px;
  margin: 5rem auto 0 auto;
}

.logoContainer {
  display: flex;
  justify-content: flex-end;
  a {
    display: flex;
    justify-content: flex-end;
  }
  img {
    max-width: 80%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.headerActionsContainer {
  display: flex;
  align-items: center;
}

.searchInputContainer {
  flex-grow: 1;
}

.searchInput {
  width: 100%;
  border: none;
  font-size: 32px;
  font-weight: 500;
  color: $primary-text;
  &::placeholder {
    color: #bfc9d9;
  }
}

.tableContainer {
  position: relative;
  max-height: 550px;
  overflow: auto;
}

.table {
  position: relative;
  td {
    color: $primary-text;
    padding: 0 0.5rem;
    line-height: 2;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
}

.tableHeader {
  position: sticky;
  top: 0;
  background-color: #f8f9fb;
  padding: 0 1rem;

  th {
    padding: 0.5rem;
    color: $primary-text;
    display: table-cell !important;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
}

.titleButton {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 22px;
  text-align: left;
  font-weight: 400;
  color: $primary-text;
  cursor: pointer;
  font-family: helveticaneue;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.5px;
}
</style>